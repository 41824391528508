/* CSS */
.center-align-header {
    text-align: center;
  }
  
.left-align-cell {
    text-align: left;
  }
  
.right-align-cell {
    text-align: right;
  }
  