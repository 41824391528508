.table-container {
    overflow-x: auto;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1em;
    font-family: "DM Sans";
    min-width: 400px;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.styled-table thead tr {

    color: #000;
    text-align: left;
    color: #000;

font-family: "DM Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;

}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    font-size: 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
    font-family: "DM Sans";
    color: #000;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

/* .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
} */

/* .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
} */

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.approve-button {
    /* background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; */
    display: flex;
width: 126.103px;
height: 35px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 3px;
background: var(--primary, #1A4FD6);
color: var(--white, #FFFFFF);
}

/* .approve-button:hover {
    
    border-radius: 3px;
background: var(--primary, #1A4FD6);
} */

.red {
    color: var(--danger, #E90000);
    font-size: 15px;
font-style: normal;
font-weight: bold;
line-height: normal;
text-transform: capitalize;


}

.orange {
    color: var(--danger, #FFBF33);
    font-size: 15px;
font-style: normal;
font-weight: bold;
line-height: normal;
text-transform: capitalize;
}

.blue {
    color: var(--Blue, #2291FF);
    font-size: 15px;
font-style: normal;
font-weight: bold;
line-height: normal;
text-transform: capitalize;
}
.table-divider {
    height: 1px;
    background-color: #dddddd;
    border: none;
}