.parentRouterDiv {
  display: grid;
  grid-template-areas: 'menu header''menu main';
  grid-template-columns: 15em 1fr;
  grid-template-rows: 5.5em 1fr;

}

.menu {
  grid-area: menu;
  z-index: 100;
}

.header {
  grid-area: header;
  margin-left: auto;
  margin-right: 1em;
  position: sticky;
  top: 0;
  background: #ffffff;
  width: 100%;
  z-index: 50;

}

.router {
  grid-area: main;
  background-color: white;
}

.colorPicker {
  position: absolute;
  z-index: 10000;
  top: 5em;
  left: -0.5em;
}