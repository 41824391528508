.pa-login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.pa-login-card {
    backdrop-filter: blur(2.5px);
    width: 724px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(256, 256, 256, 0.7);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media screen and (min-width: 2100px) {
    .pa-login-card {
        transform: scale(1.2);
    }
}

@media screen and (min-width: 1800px) and (max-width: 2100px) {
    .pa-login-card {
        transform: scale(1.1);
    }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .pa-login-card {
        transform: scale(1);
    }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
    .pa-login-card {
        transform: scale(0.9);
    }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
    .pa-login-card {
        transform: scale(0.8);
    }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
    .pa-login-card {
        transform: scale(0.7);
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .pa-login-card {
        transform: scale(0.6);
    }
}

@media screen and (max-width: 900px) {
    .pa-login-card {
        transform: scale(0.5);
    }
}

@media screen and (min-height: 600px) and (max-height: 699px) {
    .pa-login-card {
        transform: scale(0.6);
    }
}

@media screen and (min-height: 700px) and (max-height: 799px) {
    .pa-login-card {
        transform: scale(0.7);
    }
}

@media screen and (min-height: 800px) and (max-height: 900px) {
    .pa-login-card {
        transform: scale(0.8);
    }
}

.pa-login-logo {
    width: 150px;
    height: 104px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
}

.pa-login-logo-trouble {
    width: 150px;
    height: 104px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 135px;
}

.pa-login-heading {
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 32px;
    color: #202020;
    line-height: 41.66px;
    font-style: normal;
    margin-top: 16px;
}

.pa-login-sub-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #202020;
    margin-top: 8px;
}

.pa-login-password-strength {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4e4e4e;
    margin-top: 2px;
    display: flex;
}

.pa-login-email-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: #202020;
    margin-top: 54px;
    text-align: left;
    margin-left: 148px;
    margin-bottom: 16px;
}

.pa-login-password-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: #202020;
    margin-top: 32px;
    text-align: left;
    margin-left: 148px;
    margin-bottom: 16px;
}

.pa-button {
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 386px;
    height: 53px;
    background: #1A4FD6;
    border-radius: 12px;
    color: white;
    margin-top: 32px;
    font-size: 20px;
}

.pa-transfi-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
}

.pa-powered-by {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #696969;
}

.pa-powered-transfi {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #1A4FD6;
}

.pa-input-box-div {
    display: inline-block;
    width: 386px;
    background-color: #FFFFFF;
    height: 56px;
    border-radius: 12px;
    border: 0;
    box-shadow: 0px 0px 5px rgba(31, 41, 55, 0.12);
    gap: 8px;
}

.pa-input-email {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding-left: 48px;
    border-radius: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #202020;
}

.pa-input-email:focus {
    outline-color: #1A4FD6;
}

.pa-input-password {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 20px;
    padding-left: 48px;
    padding-right: 52px;
    border-radius: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #202020;
    border-bottom: 3px solid white;
}

.pa-input-password:focus {
    outline: none;
}

.pa-email-icon {
    width: 18px;
    height: 14px;
    margin-top: 21px;
    margin-bottom: 21px;
    margin-left: 19px;
}

.pa-key-icon {
    width: 18px;
    height: 18px;
    margin-top: 19px;
    margin-bottom: 19px;
    margin-left: 19px;
}

.pa-forget-password-enabled {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #1A4FD6;
    margin-top: 24px;
    cursor: pointer;
}

.pa-forget-password {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #202020;
    cursor: not-allowed;
    margin-top: 24px;
}

.pa-horizontal-line {
    width: 173.5px;
    height: 0px;
    border-bottom: 1px solid #D2D2D2;
}

.pa-horizontal-line-text {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #202020;
    margin-left: 8px;
    margin-right: 8px;
}

.pa-sign-in-with-google-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    padding: 14px 10px;
    gap: 20px;
    width: 386px;
    height: 50.34px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6.2069px rgba(31, 41, 55, 0.12);
    border-radius: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
}

.pa-card-heading {
    display: flex;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #202020;
    margin-top: 32px;
}

.pa-card-heading-icon {
    margin-top: 3px;
    margin-right: 25px;
    margin-left: 40px;
}

.pa-qr-image {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.pa-mfa-code {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #525C76;
    margin-top: 17.5px;
    margin-bottom: 17.5px;
    margin-left: 17.5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pa-copy-icon {
    width: 24px;
    height: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 33px;
    cursor: pointer;
}

.pa-otp-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 50px;
    height: 53px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(31, 41, 55, 0.12);
    border-radius: 12px;
    -moz-appearance: textfield;
    text-align: center;
}

.pa-otp-inputs:focus {
    outline-color: #1A4FD6;
}

.pa-otp-inputs::-webkit-inner-spin-button,
.pa-otp-inputs::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
