.ag-theme-quartz,
.ag-theme-quartz-dark {
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 2px;

    --ag-header-column-resize-handle-display: block;
    --ag-header-background-color: #F6F8FA;

}

.ag-row .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-column .ag-header-cell-label {
    justify-content: center;
    align-items: center;
}