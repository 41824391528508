.rdtPicker {
    border: none;
  }
  
  .rdt input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    vertical-align: middle;
    display: flex;
    align-items: center;
    box-shadow: none;
  }
  
  .rdtPicker .form-control {
    display: flex;
    align-items: center;
  }
  
  .rdt input:focus {
    outline: none;
    box-shadow: none;
  }
  